import { defineComponent } from 'vue'

import DsView from '@/components/DsView'
import { Model, Reference } from '@/components/DsForm'
import { Column } from '@/components/DsTable'
import DsTag from '@/components/DsTag'

import { ElMessageBox } from 'element-plus'
import { showMessage } from '@/utils/messages'
import { apiCall } from '@/utils/requests'

const componentModel: Model = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      label: 'Name',
      required: true,
    },
    supplier_id: {
      type: 'uuid',
      model: 'suppliers',
      label: 'Supplier',
    },
    categorie: {
      type: 'string',
      label: 'Categorie',
    },
    stock: {
      type: 'number',
      label: 'Calculated Stock',
      disabled: true
    },
    unit: {
      type: 'string',
      label: 'Unit',
    },
    commandes: {
      type: 'string',
      label: 'Commandes',
    },
    unit_valorisation: {
      type: 'number',
      label: 'Unit Valorisation',
      suffix: new Reference('root', 'unit')
    },
  }
}

const columns: Column[] = [
  { key: 'name', header: 'Name', defaultSort: 'asc' },
  {
    key: 'supplier_id',
    header: 'Supplier',
    formatter: (value: any, relations: any) => {
      if (
        value === undefined ||
        !relations.fournisseur_id ||
        !relations.fournisseur_id[value]
      )
        return '-'
      else {
        return <DsTag>{relations.fournisseur_id[value].name}</DsTag>
      }
    },
  },
  { key: 'categorie', header: 'Categorie' },
  {
    key: 'stock', header: 'Reported Stock', formatter: (value: unknown) => typeof (value) === 'string' ? value : ' - '
  },
  { key: 'unit', header: 'Unit' },
  { key: 'unit_valorisation', header: 'Unit Valorisation', formatter: (value: unknown) => value ? value + '€' : '-' },
  { key: 'commandes', header: 'Commandes' },
]


const defaultClient = () => ({
  name: '',
  fournisseur: '',
  pointure: '',
})

const Inventory: Model = {
  type: 'object',
  properties: {
    component_id: {
      type: 'uuid',
      model: 'components',
      label: 'Component',
      required: true
    },
    reported_stock: {
      type: 'number',
      label: 'Reported Stock',
      required: true
    }
  }
}

export default defineComponent({
  name: 'Components',
  components: { DsView },
  setup() {
    return () => (
      <DsView
        view={{
          collection: 'components',
          title: 'Components',
          model: componentModel,
          columns: columns,
          createButtonLabel: 'New component',
          rowKey: 'name',
          actionButtons: [
            {
              name: 'Set Inventory',
              title: 'Inventory',
              model: Inventory,
              action: async (value: any, relations: any) => {
                const selected_component = relations.components[value.component_id]
                const supply_quantity = - (selected_component.stock - value.reported_stock)
                try {
                  await ElMessageBox.confirm(
                    `You are about to log a difference of ${(supply_quantity > 0 ? '+' : '') + supply_quantity} ${selected_component.unit || ''} for ${selected_component.name}.`,
                    'Confirm',
                  )
                  await apiCall('supplies', "POST", {
                    "name": 'Regularization',
                    "component": selected_component.id,
                    "quantity": supply_quantity,
                    "date": new Date().toISOString().split('T')[0]
                  })
                  showMessage('This stock have been updated.', 'info')
                  relations.components[value.component_id].stock = value.reported_stock
                } catch (error) {
                  if (error === 'cancel') {
                    return
                  }
                  showMessage('Error', 'error')
                }
              },
              createLabel: 'Set'
            }
          ]
        }}
        defaultValue={defaultClient}
      />
    )
  },
})
